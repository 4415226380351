export default {
    'index': 'Home page',
    'aboout': 'About',
    'bussiness': 'Business cooperation',
    'homeText1': 'Let the world have no unfamiliar corners',
    'homeText2': 'We have served over 250 cities and 200 million users in 21 countries worldwide',
    'homePicTitle1': 'Science and technology',
    'homePicText1': 'Bonjour Riding is a world-leading innovative platform for dockless shared bicycle travel. Users canunlock bicycles through their mobile phones and enjoy shared travel services with bikes anytime,anywhere.',
    'homePicTitle2': 'Globalization',
    'homePicText2': 'Since the establishment of the company in August 2021, it has connected more than 15 millionshared bicycles around the world, with daily orders exceeding 30 million, providing more than 4billion times of high-efficiency, convenience, green and low-carbon for 200 million users in 400cities in many countries around the world travel services.',
    'homePicTitle3': 'Social responsibilities',
    'homePicText3': 'Bonjour Riding is committed to advocating the concept of green travel. By integrating bicycleproduction capacity and supply chain, it promotes the travel concept of shared bicycles globallyand provides convenient and efficient green travel services for major cities. To meet the needs ofusers for a better life, create an urban green transportation system, improve the efficiency ofurban travel, and provide everyone with a more convenient way of travel.',
    'homePicTitle4': 'User',
    'homePicText4': 'At present, Bonjour Riding occupies a leading position in the market in terms of downloads,users, user growth rate, number of bicycles, daily orders, and average daily use times of eachvehicle on major platforms.',
    'homePicTitle5': 'develop',
    'homePicText5': 'Bonjour Riding is an industry-leading bike-sharing platform. It adheres to the business model of sharing economy. Through the platform route, it connects bicycles shared by various bicycle manufacturers and users around the world, and develops together with global bicycle industry companies.',
    'homePicTitle6': 'future',
    'homePicText6': 'Bonjour Riding has started operations in various cities around the world and has become one of the Internet companies with a business model. It hopes to provide shared travel services for 2 billion people around the world in the future.',
    'aboutHeader1': 'about Us',
    'aboutHeader2': "The world's leading platform for dockless bike sharing",
    "aboutNav1": 'Bonjour Riding',
    "aboutNav2": 'Originality',
    "aboutNav3": 'Market share',
    "aboutNav4": 'Prospects',
    "aboutNav5": 'Founding team',
    "aboutNav1title1": "Bonjour Riding",
    "aboutNav1title1content1": "Bonjour Riding is the world's leading dockless shared bicycle travel platform. Users only need to scan the QR code to unlock the car and use it anytime, anywhere.",
    "aboutNav1title1content2": "As a global leader in shared bicycles, since its launch in August 2021, Bonjour Riding has connected more than 15 million shared bicycles around the world, with daily orders exceeding 30 million, providing 200 million users in 400 cities in many countries around the world. More than 4 billion times of efficient, convenient, green and low-carbon travel services have reduced carbon emissions by more than 1.9 million tons, which is equivalent to saving 721.29 million liters of gasoline for the society and reducing 1.275 million tons of PM2.5 emissions. Bonjour Riding has also become the world's leading bike-sharing platform.",
    "aboutNav1title1content3": "Bonjour Riding shared bicycles officially open for sharing, open and share real-time vehicle data to various cities, cooperate with the government to complete the refined operation and management of vehicles in the shared bicycle industry, and establish the industry's first big data platform standard for collaborative government management of vehicles. In the future, more vehicle data from shared bicycle platforms will be connected to create a big data platform for refined operation and management of vehicles in the entire industry.",
    "aboutNav1title1content4": "The vision of Bonjour Riding is, \"There is love everywhere in the world\". In the future, Bonjour Riding hopes not to produce bicycles, but to connect bicycles, so that people can unlock bicycles through Bonjour Riding in every corner of the world to meet the needs of short-distance transportation.",
    "aboutNav1title1content5": "Bonjour Riding also welcomes users to share their own bicycles to join Bonjour Riding with an open platform and spirit of sharing. It mobilizes the urban bicycle stock market with the Internet innovation model of the sharing economy, improves the efficiency of bicycle use, and saves more space for the city. Bonjour Riding advocates civilized car use, guides users to standardize the use of Bonjour Riding shared bicycles through technical means, and cooperates with citizens and governments to optimize shared bicycle travel solutions to make cities better.",
    "aboutNav1title2": "Originality",
    "aboutNav1title2content1": "In recent years, public bicycles have become the business card of some well-known cities in the world, and many cities in Asia, Europe, etc. have world-famous public bicycle projects. Traditional public bicycles are piled, occupy a large area, and cannot be returned anytime and anywhere, requiring the government to invest a lot of money in operation. In 2010, a company called social bicycle in Europe and the United States proposed a bicycle sharing plan, but users still need to find street lights, parking piles and other pillars for parking bicycles.",
    "aboutNav1title2content2": "In 2021, Bonjour Riding will enable the sharing of bicycles in the way of dockless plus sharing on a global scale. The breaking of the shackles has made shared bicycles truly convenient anytime and anywhere, and has revitalized the concept of public bicycles.",
    "aboutNav1title2content3": "Bicycle travel + mobile Internet technology, the proposal of this model has improved the efficiency of bicycle sharing and use, and the bicycle sharing model has increased the efficiency of a bicycle from 5 minutes to 76 minutes, an increase of 16 times. The number of people who can serve has changed from 1 person to at least 10 people, an increase of at least 10 times. Through the sharing economy, from the transfer of ownership to the sharing of use rights, it improves the efficiency of bicycle use, reduces the waste of urban resources, reduces congestion for cities, helps cities save more space, and promotes green and low-carbon travel.",
    "aboutNav1title2content4": "This is where the ingenuity and significance of the stakeless sharing model lies.",
    "aboutNav1title3": "Market share",
    "aboutNav1title3content1": "At present, Bonjour Riding occupies a leading position in the market in terms of downloads, users, user growth rate, number of bicycles, daily orders, and average daily use times of each vehicle on all major platforms.",
    "aboutNav1title4": "Prospects",
    "aboutNav1title4content1": "Bonjour Riding is an industry-leading bike-sharing platform that adheres to the business model of the sharing economy. Through the platform route, it connects bicycles shared by various bicycle manufacturers and users around the world, and develops together with most bicycle industry companies.",
    "aboutNav1title4content2": "The company plans to develop the rest of the global market within 3 years and create global integrated management. The goal of Bonjour Riding is to cover more than 80% of the global market, so that everyone knows the existence of Bonjour Riding. It has promoted the economic development of a country, so that more friends with dreams can join Bonjour Riding and become partners of Bonjour Riding, so that those who have the ability but have nowhere to release can fully demonstrate their abilities in Bonjour Riding , reflect their own value.",
    "aboutNav1title5": "Founding team",
    "aboutNav1title5content1": "Osman, founder and CEO of Bonjour Riding shared bicycle. Out of love for bicycles, Bonjour Riding was founded in 2021 to share bicycles, enabling the global \"dockless bicycle sharing\" model, and is committed to solving users' last mile travel problems with the concept of \"sharing, trust, and openness\". Bonjour Riding takes \"love in any corner of the world\" as its vision, and is a shared travel platform that affects the world.",
    'bussinessRow1Title': 'Company Size',
    'bussinessRow1b1': '200 million+',
    'bussinessRow1p1': 'real name user authentication',
    'bussinessRow1b2': '400+',
    'bussinessRow1p2': 'Cities at home and abroad',
    'bussinessRow1b3': '4 billion+',
    'bussinessRow1p3': 'Big data for short trips',
    'bussinessRow2Title': 'User portrait',
    'bussinessRow3Title': 'Brand recommendation',
    'bussinessRow3P1': 'Standardized urban resources + customized creative marketing resource integration',
    'bussinessRow3P2': 'Create an urban green transportation system, improve the efficiency of urban travel, and provide everyone with a more convenient way of travel.',
    'bussinessRow4Title': 'Crowd + LBS accurate delivery',
    'bussinessRow4p1': 'Precise exposure of store brands, rewards, coupons and other forms + drainage to stores, continuously improving advertising efficiency and production capacity.',
    'bussinessRow4p2': 'Accurately expose and attract customers to the store, continuously improving advertising efficiency and output',
    'bussinessRow4p3': 'Starting from 1000 yuan, limited time refund welfare is in progress!',
    'bussinessRow5Title': 'Breeze Action · Enterprise Annual Card',
    'bussinessRow5PicText1': 'Rental fee: Users scan the QR code to rent a shared bicycle and pay a certain rental fee. Typically, fees are calculated based on the length of time the car was rented, either by the hour or by the minute.',
    'bussinessRow5PicText2': 'Advertising revenue: Shared bicycle companies can place advertisements on bicycles and obtain advertising revenue from advertisers. Such advertisements may be body advertisements, basket advertisements or handlebar advertisements.',
    'bussinessRow5PicText3': 'Sponsorship of designated parking spots: Shared bicycle companies can cooperate with merchants, enterprises or governments to set up designated parking spots in specific areas and sponsor shared bicycles to be parked in the area to enhance brand exposure and popularity.',
    'bussinessRow5PicText4': 'Deposit interest: Shared bicycle companies usually require users to pay a certain deposit, which will be put into a bank account, and the company can use these deposits to obtain bank interest income.',
    'bussinessRow5PicText5': 'The above are some of the main profit models of shared bicycles. In actual operation, different shared bicycle companies may adopt different combinations to achieve profitability and sustainable development.',
    'bussinessRow5Item1Title': 'Employee Benefits',
    'bussinessRow5Item1Content': 'The equipment has been ridden for 1800 hours, which can be exchanged for exclusive benefits',
    'bussinessRow5Item2Title': 'bonjour riding in action',
    'bussinessRow5Item2Content': 'Use every ride to really help medical charity, educational charity, rescue and other public welfare causes',
    'bussinessRow6Title': 'join us',
    'bussinessRow6Time': 'Our working hours are Monday to Friday (9:00am-3:00pm)',
}