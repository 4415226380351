export default {
    'index': 'Página de inicio',
    'aboout': 'Sobre nosotros',
    'bussiness': 'Cooperación comercial',
    'homeText1': 'Que no haya rincones desconocidos en el mundo',
    'homeText2': 'Hemos servido a 21 países de todo el mundo, más de 250 ciudades, 200 millones de usuarios',
    'homePicTitle1': 'Ciencia y Tecnología',
    'homePicText1': 'Bonjour Riding es una plataforma innovadora líder en el mundo para viajes en bicicletacompartidos sin estación. Los usuarios pueden desbloquear bicicletas a través de sus teléfonosmóviles y disfrutar de servicios de viajes compartidos con bicicletas en cualquier momento y encualquier lugar. Como líder mundial en bicicletas compartida',
    'homePicTitle2': 'Globalización',
    'homePicText2': 'Desde el establecimiento de la empresa en agosto de 2021, ha conectado más de 15 millones debicicletas compartidas en todo el mundo, con pedidos diarios que superan los 30 millones,brindando más de 4 mil millones de viajes eficientes, convenientes, ecológicos y bajos encarbono para 200 millones de usuarios en 400 ciudades en muchos países de todo el mundoservicios de viaje.',
    'homePicTitle3': 'Responsabilidades sociales',
    'homePicText3': 'Bonjour Riding se compromete a promover el concepto de viajes ecológicos. Al integrar la capacidad de producción de bicicletas y la cadena de suministro, promueve el concepto de viaje de bicicletas compartidas a nivel mundial y brinda servicios de viaje ecológicos convenientes y eficientes para las principales ciudades. Para satisfacer las necesidades de los usuarios de una vida mejor, cree un sistema de transporte urbano verde, mejore la eficiencia de los viajes urbanos y brinde a todos una forma más conveniente de viajar.',
    'homePicTitle4': 'Usuario',
    'homePicText4': 'En la actualidad, Bonjour Riding ocupa una posición de liderazgo en el mercado en términos de descargas, usuarios, tasa de crecimiento de usuarios, cantidad de bicicletas, pedidos diarios y tiempos promedio de uso diario de cada vehículo en las principales plataformas.',
    'homePicTitle5': 'desarrollar',
    'homePicText5': 'Bonjour Riding es una plataforma de bicicletas compartidas líder en la industria. Se adhiere al modelo de negocio de economía colaborativa. A través de la ruta de la plataforma, conecta bicicletas compartidas por varios fabricantes y usuarios de bicicletas en todo el mundo, y se desarrolla junto con empresas mundiales de la industria de bicicletas.',
    'homePicTitle6': 'futuro',
    'homePicText6': 'Bonjour Riding ha iniciado operaciones en varias ciudades del mundo y se ha convertido en una de las empresas de Internet con modelo de negocio. Espera proporcionar servicios de viajes compartidos para 2 mil millones de personas en todo el mundo en el futuro.',
    'homeLiText1': '一辆ofo的诞生',
    'homeLiText2': 'ofo科技访谈',
    'homeLiText3': 'ofo小黄车"奇点"大数据',
    'aboutHeader1': 'sobre nosotros',
    'aboutHeader2': 'La plataforma líder mundial para compartir bicicletas sin estación',
    "aboutNav1": 'Bonjour Riding',
    "aboutNav2": 'originalidad',
    "aboutNav3": 'cuota de mercado',
    "aboutNav4": 'perspectivas',
    "aboutNav5": 'equipo fundador',
    "aboutNav1title1": "Bonjour Riding",
    "aboutNav1title1content1": "Bonjour Riding es la plataforma de viaje en bicicleta compartida sin muelle líder en el mundo. Los usuarios solo necesitan escanear el código QR para desbloquear el automóvil y usarlo en cualquier momento y en cualquier lugar.",
    "aboutNav1title1content2": "Como líder mundial en bicicletas compartidas, desde su lanzamiento en agosto de 2021, Bonjour Riding ha conectado más de 15 millones de bicicletas compartidas en todo el mundo, con pedidos diarios que superan los 30 millones, abasteciendo a 200 millones de usuarios en 400 ciudades de muchos países del mundo. Más de 4 mil millones de veces de servicios de viaje eficientes, convenientes, ecológicos y bajos en carbono han reducido las emisiones de carbono en más de 1,9 millones de toneladas, lo que equivale a ahorrar 721,29 millones de litros de gasolina para la sociedad y reducir 1,275 millones de toneladas de PM2.5 emisiones Bonjour Riding también se ha convertido en la plataforma de bicicletas compartidas líder en el mundo.",
    "aboutNav1title1content3": "Las bicicletas compartidas Bonjour Riding se abren oficialmente para compartir, abrir y compartir datos de vehículos en tiempo real en varias ciudades, cooperar con el gobierno para completar la operación refinada y la gestión de vehículos en la industria de bicicletas compartidas, y establecer el primer estándar de plataforma de big data de la industria para gestión colaborativa gubernamental de vehículos. En el futuro, se conectarán más datos de vehículos de plataformas de bicicletas compartidas para crear una plataforma de big data para una operación y gestión refinadas de vehículos en toda la industria.",
    "aboutNav1title1content4": "La visión de Bonjour Riding es: \"Hay amor en todas partes del mundo\". En el futuro, Bonjour Riding espera no producir bicicletas, sino conectar bicicletas, para que las personas puedan desbloquear bicicletas a través de Bonjour Riding en todos los rincones del mundo para satisfacer las necesidades del transporte de corta distancia.",
    "aboutNav1title1content5": "Bonjour Riding también invita a los usuarios a compartir sus propias bicicletas para unirse a Bonjour Riding con una plataforma abierta y un espíritu de compartir. Moviliza el mercado de valores de bicicletas urbanas con el modelo de innovación de Internet de la economía compartida, mejora la eficiencia del uso de bicicletas y ahorra más espacio para la ciudad. Bonjour Riding aboga por el uso civilizado del automóvil, guía a los usuarios para estandarizar el uso de las bicicletas compartidas Bonjour Riding a través de medios técnicos y coopera con los ciudadanos y los gobiernos para optimizar las soluciones de viajes compartidos en bicicleta para mejorar las ciudades.",
    "aboutNav1title2": "originalidad",
    "aboutNav1title2content1": "En los últimos años, las bicicletas públicas se han convertido en la tarjeta de presentación de algunas ciudades conocidas en el mundo, y muchas ciudades de Asia, Europa, etc. tienen proyectos de bicicletas públicas de fama mundial. Las bicicletas públicas tradicionales están apiladas, ocupan una gran área y no se pueden devolver en ningún momento ni en ningún lugar, lo que requiere que el gobierno invierta mucho dinero en su funcionamiento. En 2010, una empresa llamada bicicleta social en Europa y Estados Unidos propuso un plan de bicicletas compartidas, pero los usuarios aún necesitan encontrar farolas, pilas de estacionamiento y otros pilares para estacionar bicicletas.",
    "aboutNav1title2content2": "En 2021, Bonjour Riding permitirá el uso compartido de bicicletas en el modo de uso compartido sin muelle más el uso compartido a escala mundial. La ruptura de los grilletes ha hecho que las bicicletas compartidas sean realmente cómodas en cualquier momento y en cualquier lugar, y ha revitalizado el concepto de bicicletas públicas.",
    "aboutNav1title2content3": "Viajes en bicicleta + tecnología de Internet móvil, la propuesta de este modelo ha mejorado la eficiencia del uso y uso compartido de bicicletas, y el modelo de bicicletas compartidas ha aumentado la eficiencia de una bicicleta de 5 minutos a 76 minutos, un aumento de 16 veces. El número de personas que pueden servir ha cambiado de 1 persona a al menos 10 personas, un aumento de al menos 10 veces. A través de la economía colaborativa, desde la transferencia de la propiedad hasta la distribución de los derechos de uso, mejora la eficiencia del uso de la bicicleta, reduce el desperdicio de recursos urbanos, reduce la congestión de las ciudades, ayuda a las ciudades a ahorrar más espacio y promueve el uso ecológico y bajo en carbono. viajar.",
    "aboutNav1title2content4": "Aquí es donde radica el ingenio y la importancia del modelo de participación sin participación.",
    "aboutNav1title3": "cuota de mercado",
    "aboutNav1title3content1": "En la actualidad, Bonjour Riding ocupa una posición de liderazgo en el mercado en términos de descargas, usuarios, tasa de crecimiento de usuarios, cantidad de bicicletas, pedidos diarios y tiempos promedio de uso diario de cada vehículo en todas las plataformas principales.",
    "aboutNav1title4": "perspectivas",
    "aboutNav1title4content1": "Bonjour Riding es una plataforma de bicicletas compartidas líder en la industria que se adhiere al modelo comercial de la economía compartida. A través de la ruta de la plataforma, conecta bicicletas compartidas por varios fabricantes y usuarios de bicicletas en todo el mundo, y se desarrolla junto con la mayoría de las empresas de la industria de bicicletas.",
    "aboutNav1title4content2": "La compañía planea desarrollar el resto del mercado global dentro de 3 años y crear una gestión integrada global. El objetivo de Bonjour Riding es cubrir más del 80% del mercado global, para que todos conozcan la existencia de Bonjour Riding. Ha promovido el desarrollo económico de un país, para que más amigos con sueños puedan unirse a Bonjour Riding y convertirse en socios de Bonjour Riding, para que aquellos que tienen la habilidad pero no tienen dónde liberar puedan demostrar plenamente sus habilidades en Bonjour Riding, reflejar su valor propio.",
    "aboutNav1title5": "equipo fundador",
    "aboutNav1title5content1": "Osman, fundador y CEO de Bonjour Riding bicicleta compartida. Por amor a las bicicletas, Bonjour Riding se fundó en 2021 para compartir bicicletas, lo que permite el modelo global de \"compartir bicicletas sin estación\", y se compromete a resolver los problemas de viaje de última milla de los usuarios con el concepto de \"compartir, confiar y apertura\". Bonjour Riding tiene como visión \"el amor en cualquier rincón del mundo\" y es una plataforma de viajes compartida que afecta al mundo",
    'bussinessRow1Title': 'Tamaño de la empresa',
    'bussinessRow1b1': '200 millones+',
    'bussinessRow1p1': 'autenticación de usuario de nombre real',
    'bussinessRow1b2': '400+',
    'bussinessRow1p2': 'Ciudades en casa y en el extranjero',
    'bussinessRow1b3': '4 mil millones +',
    'bussinessRow1p3': 'Big data para viajes cortos',
    'bussinessRow2Title': 'Retrato del usuario',
    'bussinessRow3Title': 'recomendación de marca',
    'bussinessRow3P1': 'Recursos urbanos estandarizados + integración personalizada de recursos de marketing creativo',
    'bussinessRow3P2': 'Cree un sistema de transporte urbano verde, mejore la eficiencia de los viajes urbanos y brinde a todos una forma más conveniente de viajar.',
    'bussinessRow4Title': 'Entrega precisa Multitud + LBS',
    'bussinessRow4p1': 'Exposición precisa de marcas de tiendas, recompensas, cupones y otras formas + drenaje a las tiendas, mejorando continuamente la eficiencia publicitaria y la capacidad de producción.',
    'bussinessRow4p2': 'Exposición precisa + drenaje a la tienda para mejorar continuamente la eficiencia y la producción de la publicidad',
    'bussinessRow4p3': '¡¡ la inversión inicial de 1000 yuanes y el reembolso de los beneficios por un tiempo limitado están en curso!',
    'bussinessRow5Title': 'Acción de la brisa · tarjeta anual de la empresa',
    'bussinessRow5PicText1': 'Tarifa de alquiler: los usuarios escanean el código QR para alquilar una bicicleta compartida y pagar una determinada tarifa de alquiler. Por lo general, las tarifas se calculan en función del tiempo que se alquiló el automóvil, ya sea por hora o por minuto.',
    'bussinessRow5PicText2': 'Ingresos publicitarios: las empresas de bicicletas compartidas pueden colocar anuncios en bicicletas y obtener ingresos publicitarios de los anunciantes. Dichos anuncios pueden ser anuncios de carrocería, anuncios de cestas o anuncios de manillar.',
    'bussinessRow5PicText3': 'Patrocinio de lugares de estacionamiento designados: las empresas de bicicletas compartidas pueden cooperar con comerciantes, empresas o gobiernos para establecer lugares de estacionamiento designados en áreas específicas y patrocinar bicicletas compartidas para que se estacionen en el área para mejorar la exposición y la popularidad de la marca.',
    'bussinessRow5PicText4': 'Intereses de depósito: las empresas de bicicletas compartidas suelen exigir a los usuarios que paguen un depósito determinado, que se depositará en una cuenta bancaria, y la empresa puede utilizar estos depósitos para obtener ingresos por intereses bancarios.',
    'bussinessRow5PicText5': 'Los anteriores son algunos de los principales modelos de beneficio de las bicicletas compartidas. En la operación real, diferentes empresas de bicicletas compartidas pueden adoptar diferentes combinaciones para lograr rentabilidad y desarrollo sostenible',
    'bussinessRow5Item1Title': 'Beneficios para empleados',
    'bussinessRow5Item1Content': 'El equipo se ha montado durante 1800 horas, que se pueden canjear por beneficios exclusivos',
    'bussinessRow5Item2Title': 'bonjour montando en acción',
    'bussinessRow5Item2Content': 'Use cada viaje para ayudar realmente a la caridad médica, la caridad educativa, el rescate y otras causas de bienestar público.',
    'bussinessRow6Title': 'Únete a nosotros',
    'bussinessRow6Time': 'Nuestro horario de trabajo es de Lunes a Viernes (9:00am-3:00pm)',
}