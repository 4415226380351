export default {
    'index': 'Главная страница',
    'aboout': 'о нас',
    'bussiness': 'Коммерческое сотрудничество',
    'homeText1': 'Мир без чужих уголков.',
    'homeText2': 'Мы обслуживаем более 250 городов и 200 миллионов пользователей в 21 стране мира',
    'homePicTitle1': 'наука и технология',
    'homePicText1': 'Bonjour Riding — это ведущая в мире инновационная платформа для совместных поездок на велосипеде без дока. Пользователи могут разблокировать велосипеды через свои мобильные телефоны и пользоваться совместными туристическими услугами с велосипедами в любое время и в любом месте. Являясь мировым лидером в области общих велосипедов',
    'homePicTitle2': 'глобализация',
    'homePicText2': 'С момента основания компании в августе 2021 года она подключила более 15 миллионов общих велосипедов по всему миру, причем ежедневные заказы превышают 30 миллионов, обеспечивая более 4 миллиардов эффективных, удобных, экологичных и низкоуглеродных поездок для 200 миллионов пользователей в 400 городов во многих странах мира туристические услуги.',
    'homePicTitle3': 'социальная ответственность',
    'homePicText3': 'Bonjour Riding стремится продвигать концепцию экологичного путешествия. Объединяя производственные мощности велосипедов и цепочку поставок, компания продвигает концепцию совместного использования велосипедов во всем мире и предоставляет удобные и эффективные экологически чистые туристические услуги для крупных городов. Чтобы удовлетворить потребности пользователей в лучшей жизни, создайте городскую зеленую транспортную систему, повысьте эффективность городских поездок и предоставьте каждому более удобный способ передвижения.',
    'homePicTitle4': 'пользователь',
    'homePicText4': 'В настоящее время Bonjour Riding занимает лидирующие позиции на рынке с точки зрения количества загрузок, пользователей, темпов роста числа пользователей, количества велосипедов, ежедневных заказов и среднего времени ежедневного использования каждого транспортного средства на основных платформах.',
    'homePicTitle5': 'развивать',
    'homePicText5': 'Bonjour Riding — это ведущая в отрасли платформа для обмена велосипедами. Он придерживается бизнес-модели экономики совместного потребления. Через маршрут платформы он соединяет велосипеды, используемые различными производителями и пользователями велосипедов по всему миру, и развивается вместе с глобальными компаниями велосипедной индустрии.',
    'homePicTitle6': 'будущее',
    'homePicText6': 'Bonjour Riding начала свою деятельность в разных городах мира и стала одной из интернет-компаний с бизнес-моделью. В будущем компания надеется предоставить совместные туристические услуги для 2 миллиардов человек по всему миру.',
    'homeLiText1': '一辆ofo的诞生',
    'homeLiText2': 'ofo科技访谈',
    'homeLiText3': 'ofo小黄车"奇点"大数据',
    'aboutHeader1': 'о нас',
    'aboutHeader2': 'Ведущая в мире платформа для проката велосипедов без док-станции',
    "aboutNav1": 'Bonjour Riding',
    "aboutNav2": 'оригинальность',
    "aboutNav3": 'доля рынка',
    "aboutNav4": 'перспективы',
    "aboutNav5": 'команда основателей',
    "aboutNav1title1": "Bonjour Riding",
    "aboutNav1title1content1": "Bonjour Riding — это ведущая в мире платформа для совместного использования велосипедов без док-станции. Пользователям нужно только отсканировать QR-код, чтобы разблокировать автомобиль и использовать его в любое время и в любом месте.",
    "aboutNav1title1content2": "Будучи мировым лидером в области проката велосипедов, с момента запуска в августе 2021 года Bonjour Riding подключила более 15 миллионов общих велосипедов по всему миру, причем ежедневные заказы превышают 30 миллионов, обеспечивая 200 миллионов пользователей в 400 городах во многих странах мира. Более чем в 4 миллиарда раз эффективные, удобные, экологически чистые и низкоуглеродные туристические услуги позволили сократить выбросы углерода более чем на 1,9 миллиона тонн, что эквивалентно экономии 721,29 миллиона литров бензина для общества и сокращению 1,275 миллиона тонн PM2,5. выбросы. Bonjour Riding также стала ведущей в мире платформой для обмена велосипедами.",
    "aboutNav1title1content3": "Общие велосипеды Bonjour Riding официально открыты для обмена, открытия и обмена данными о транспортных средствах в реальном времени с различными городами, сотрудничества с правительством для завершения усовершенствованной эксплуатации и управления транспортными средствами в индустрии общих велосипедов, а также установления первого в отрасли стандарта платформы больших данных для совместное государственное управление транспортными средствами. В будущем больше данных о транспортных средствах с общих велосипедных платформ будет подключено для создания платформы больших данных для усовершенствованной эксплуатации и управления транспортными средствами во всей отрасли.",
    "aboutNav1title1content4": "Видение Bonjour Riding таково: «Везде в мире есть любовь». В будущем Bonjour Riding надеется не производить велосипеды, а соединять велосипеды, чтобы люди могли разблокировать велосипеды через Bonjour Riding в любом уголке мира, чтобы удовлетворить потребности в транспорте на короткие расстояния.",
    "aboutNav1title1content5": "Bonjour Riding также приглашает пользователей поделиться своими велосипедами, чтобы присоединиться к Bonjour Riding с открытой платформой и духом обмена. Он мобилизует городской рынок велосипедов с помощью инновационной интернет-модели экономики совместного использования, повышает эффективность использования велосипедов и экономит больше места для города. Bonjour Riding выступает за цивилизованное использование автомобилей, помогает пользователям стандартизировать использование общих велосипедов Bonjour Riding с помощью технических средств и сотрудничает с гражданами и правительствами для оптимизации решений для совместных поездок на велосипеде, чтобы сделать города лучше.",
    "aboutNav1title2": "оригинальность",
    "aboutNav1title2content1": "В последние годы общественные велосипеды стали визитной карточкой некоторых известных городов мира, а многие города Азии, Европы и т. д. имеют всемирно известные проекты общественных велосипедов. Традиционные общественные велосипеды свалены в кучу, занимают большую площадь и не могут быть возвращены в любое время и в любом месте, что требует от правительства вложения больших денег в эксплуатацию. В 2010 году компания Social Bike в Европе и США предложила план совместного использования велосипедов, но пользователям все еще нужно найти уличные фонари, парковочные сваи и другие опоры для парковки велосипедов.",
    "aboutNav1title2content2": "В 2021 году Bonjour Riding позволит совместно использовать велосипеды без док-станции и совместно использовать их в глобальном масштабе. Разрушение кандалов сделало общие велосипеды по-настоящему удобными в любое время и в любом месте и возродило концепцию общественных велосипедов.",
    "aboutNav1title2content3": "Путешествие на велосипеде + технология мобильного Интернета, предложение этой модели повысило эффективность совместного использования и использования велосипедов, а модель совместного использования велосипедов увеличила эффективность велосипеда с 5 минут до 76 минут, увеличившись в 16 раз. Количество людей, которые могут служить, изменилось с 1 человека до не менее 10 человек, увеличившись не менее чем в 10 раз. Благодаря экономике совместного использования, от передачи права собственности до совместного использования прав, повышается эффективность использования велосипедов, сокращается растрата городских ресурсов, уменьшается затор в городах, помогает городам экономить больше места и продвигает экологически чистые и низкоуглеродные технологии. путешествовать.",
    "aboutNav1title2content4": "Именно в этом заключается изобретательность и значимость модели обмена без доли.",
    "aboutNav1title3": "доля рынка",
    "aboutNav1title3content1": "В настоящее время Bonjour Riding занимает лидирующие позиции на рынке с точки зрения количества загрузок, числа пользователей, скорости роста числа пользователей, количества велосипедов, ежедневных заказов и среднего времени ежедневного использования каждого транспортного средства на всех основных платформах.",
    "aboutNav1title4": "перспективы",
    "aboutNav1title4content1": "Bonjour Riding — это ведущая в отрасли платформа для обмена велосипедами, которая придерживается бизнес-модели экономики совместного использования. Через маршрут платформы он соединяет велосипеды, используемые различными производителями и пользователями велосипедов по всему миру, и развивается вместе с большинством компаний велосипедной индустрии.",
    "aboutNav1title4content2": "Компания планирует освоить остальную часть мирового рынка в течение 3 лет и создать глобальный интегрированный менеджмент. Цель Bonjour Riding — охватить более 80% мирового рынка, чтобы все знали о существовании Bonjour Riding. Это способствовало экономическому развитию страны, чтобы больше друзей с мечтами могли присоединиться к Bonjour Riding и стать партнерами Bonjour Riding, чтобы те, у кого есть способности, но которым некуда высвобождать, могли полностью продемонстрировать свои способности в Bonjour Riding, отразить свои собственное значение.",
    "aboutNav1title5": "команда основателей",
    "aboutNav1title5content1": "Осман, основатель и генеральный директор компании Bonjour Riding, которая занимается общим велосипедом. Из любви к велосипедам компания Bonjour Riding была основана в 2021 году для обмена велосипедами, что позволяет использовать глобальную модель «обмена велосипедами без док-станции» и стремится решать проблемы пользователей, связанные с поездками на последней миле, с помощью концепции «обмена, доверия и открытости». Bonjour Riding считает своим видением «любовь в любом уголке мира» и представляет собой общую платформу для путешествий, которая влияет на мир.",
    'bussinessRow1Title': 'Размер компании',
    'bussinessRow1b1': '200 миллионов+',
    'bussinessRow1p1': 'аутентификация пользователя по реальному имени',
    'bussinessRow1b2': '400+',
    'bussinessRow1p2': 'Города дома и за границей',
    'bussinessRow1b3': '4 миллиарда+',
    'bussinessRow1p3': 'Большие данные для коротких поездок',
    'bussinessRow2Title': 'Портрет пользователя',
    'bussinessRow3Title': 'Рекомендация бренда',
    'bussinessRow3P1': 'Стандартизированные городские ресурсы + индивидуальная интеграция креативных маркетинговых ресурсов',
    'bussinessRow3P2': 'Создайте городскую зеленую транспортную систему, повысьте эффективность городских поездок и предоставьте каждому более удобный способ передвижения.',
    'bussinessRow4Title': 'Толпа + точная доставка LBS',
    'bussinessRow4p1': 'Точное представление брендов магазинов, вознаграждений, купонов и других форм + отток в магазины, постоянное повышение эффективности рекламы и производственных мощностей.',
    'bussinessRow4p2': 'Контент оригинал, бонусы, купоны и другие формы',
    'bussinessRow4p3': 'Тысяча юаней инвестиций, ограниченное время для пополнения пособий продолжается!',
    'bussinessRow5Title': 'Операция "Бриз" - ежегодная карта компании',
    'bussinessRow5PicText1': 'Арендная плата: пользователи сканируют QR-код, чтобы арендовать общий велосипед и оплатить определенную арендную плату. Как правило, сборы рассчитываются на основе продолжительности аренды автомобиля, либо по часам, либо по минутам.',
    'bussinessRow5PicText2': 'Доходы от рекламы. Компании по совместному использованию велосипедов могут размещать рекламу на велосипедах и получать доход от рекламы от рекламодателей. Такой рекламой может быть реклама на теле, реклама в корзине или реклама на руле.',
    'bussinessRow5PicText3': 'Спонсорство выделенных парковочных мест. Компании, занимающиеся совместным использованием велосипедов, могут сотрудничать с продавцами, предприятиями или правительствами для создания выделенных парковочных мест в определенных районах и спонсировать общие велосипеды, которые будут припаркованы в этом районе, чтобы повысить узнаваемость бренда и популярность.',
    'bussinessRow5PicText4': 'Депозитные проценты. Компании по совместному использованию велосипедов обычно требуют от пользователей внесения определенного депозита, который будет переведен на банковский счет, и компания может использовать эти депозиты для получения банковского процентного дохода.',
    'bussinessRow5PicText5': 'Выше приведены некоторые из основных моделей получения прибыли от совместного использования велосипедов. В реальной работе разные велосипедные компании могут использовать разные комбинации для достижения прибыльности и устойчивого развития.',
    'bussinessRow5Item1Title': 'Выплаты работникам',
    'bussinessRow5Item1Content': 'На экипировке отработано 1800 часов, которые можно обменять на эксклюзивные преимущества.',
    'bussinessRow5Item2Title': 'бонжур езда в действии',
    'bussinessRow5Item2Content': 'Используйте каждую поездку, чтобы действительно помочь медицинской благотворительности, образовательной благотворительности, спасению и другим общественным делам.',
    'bussinessRow6Title': 'Присоединяйтесь к нам',
    'bussinessRow6Time': 'Наше рабочее время с понедельника по пятницу (9:00-15:00)',
}