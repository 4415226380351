import VueI18n from 'vue-i18n'
import zhLang from './lang/zh'
import enLang from './lang/en'
import ruLang from './lang/ru'
import esLang from './lang/es'
import Vue from 'vue'
Vue.use(VueI18n)
let locale = localStorage.getItem('lang')&&JSON.parse(localStorage.getItem('lang')).value || 'zh-ZH'
let messages = {
    "zh-ZH": zhLang,
    "en-US": enLang,
    "ru-RU": ruLang,
    "es-ES": esLang,
}
export default new VueI18n({
    locale: locale,  // 语言标识
    messages: messages,
    silentTranslationWarn: true // 去除console中黄色报错
})