export default {
    'Home': '首页',
    'ProductIntroduction': '产品介绍',
    'CooperationApplication': '合作申请',
    'ChannelCooperation': '渠道合作',
    'AdCooperation': '广告合作',
    'JoinNow': '马上加入',
    'AboutUs': '关于我们',
    'QuickLinks': '快速链接',
    'ProductList': '产品列表',
    'ProductIntroduce': '产品介绍',
    'ProductTitle1': '桌面充电柜',
    'ProductTitle2': '组合充电柜',
    'ProductTitle3': '大屏充电终端',
    'ProductTitle4': '移动电源',
    'ProductTitle5': '电动自行车充电柜',
    'ContactUs': '联系我们',
    'AdContact': '广告合作邮箱',
    'BussContact': '业务合作邮箱',
    'ReportContact': '廉正举报邮箱',
    'KfContact': '客服邮箱',
    'PhoneContact': '客服电话',
    'FollowUs': '关注我们',
    'AliPay': '支付宝',
    'WeChat': '微信',
    'FooterTip': '投资有风险 选择需谨慎',
    'FooterName': '深圳科技有限公司',
    'FooterBA': '粤公网安备',
    'BackBtn': '回到顶部',
    'HomeContentTitle1': '全国95%覆盖',
    'HomeContentText1': '随时随地方便租借',
    'HomeContentTitle2': '归还便捷',
    'HomeContentText2': '简单操作，拒绝等待',
    'HomeContentTitle3': '免押金',
    'HomeContentText3': '信用分达标即享受免押租借',
    'HomeContentTitle4': '安全无忧',
    'HomeContentText4': '高规格品控，轻松借放心充',
    'HomeContentTitle5': '三线快充',
    'HomeContentText5': '适用于99%的手机',
    'AboutPcTitle1': '企业介绍',
    'AboutPcTitle2': '历史大事记',
    'DialogTitle': '请选择合作模式',
    'DialogTypeTitle1': '门店入驻',
    'DialogTypeText1': '我有门店，想要摆放充电宝机柜',
    'DialogTypeTitle2': '成为代理',
    'DialogTypeText2': '我想成为合伙人和分成',
    'DialogBtn': '立即联系'
}