<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      window.onresize = () => {
        // 设置基准值
        let sUserAgent = navigator.userAgent.toLowerCase()
        let bIsWM = /mobile/.test(sUserAgent)
        let baseFontSize = document.documentElement.clientWidth / 375 * 16; // 这里假设设计图宽度为375px，所以基准值为20px
        !bIsWM && (baseFontSize = 32)
        document.documentElement.style.fontSize = `${baseFontSize}px`;
      }
    },
    destroyed() {
      localStorage.removeItem('language')
    }
  }
</script>

<style lang="scss">
* {
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
  html, body{
    overflow-x: hidden;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    background-color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.4375rem;
  }
  html, body, p, ul, li, h1, h2, h3, h4, h5, h6, input{
    padding: 0;
    margin: 0;
  }
  ul, li{
    list-style: none;
  }
  a{
    text-decoration: none;
    -webkit-tap-highlight-color:rgba(0,0,0,0) ;
    color: #85cf19;
  }
  a,
  a:hover,
  a:active,
  a:visited,
  a:link,
  a:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    text-decoration: none;
  }
</style>
